import { Box } from "@mui/material";

export const Header = () => {
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "white",
        paddingY: 2,
        marginBottom: 4,
      }}
    >
      <img
        alt="CosmosGPT Horizontal Logo"
        src={`${process.env.PUBLIC_URL}/images/cosmosgpt.horizontal.svg`}
      />
      <Box display="flex" flexDirection="row" gap={2}>
        <img alt="Telegram" src={`${process.env.PUBLIC_URL}/images/tg.svg`} />
        <img
          alt="Twitter"
          src={`${process.env.PUBLIC_URL}/images/twitter.svg`}
        />
      </Box>
    </Box>
  );
};
