import {Chain, ChainDirectory} from '@tedcryptoorg/cosmos-directory'
import BigNumber from 'bignumber.js'

const cache: {[key: string]: Chain} = {}

export async function getChain(chainSlug: string): Promise<Chain>
{
    switch (chainSlug) {
        case 'osmo':
            chainSlug = 'osmosis'
            break
    }

    if (!(chainSlug in cache)) {
        cache[chainSlug] = (await (new ChainDirectory()).getChainData(chainSlug)).chain
    }

    return cache[chainSlug]
}

export async function getUToken(chainSlug: string, amount: BigNumber, denom: string): Promise<{denom: string, amount: BigNumber}>
{
    const chain = await getChain(chainSlug)
    for (const asset of chain.assets) {
        if (asset.denom === denom) {
            return {denom: denom, amount: amount}
        }
        for (const unit of asset.denom_units) {
            if (unit.denom === denom) {
                return {
                    denom: asset.denom,
                    amount: amount.multipliedBy(new BigNumber(10).pow(unit.exponent))
                }
            }
        }
    }

    throw new Error('Invalid denom')
}
