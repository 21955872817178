import {MsgSendEncodeObject} from "@cosmjs/stargate";
import BigNumber from "bignumber.js";
import {MsgSend} from "cosmjs-types/cosmos/bank/v1beta1/tx";
import {getChain, getUToken} from "../../util/chainDirectory";
import {getPrefixFromWallet} from "../../util/walletTools";
import {DeliverTxResponse} from "@cosmjs/stargate/build/stargateclient";

export default class CosmosClient
{
    constructor(
        private readonly signAndBroadcast: (messages: MsgSendEncodeObject[], fee?: any, memo?: string, type?: any) => Promise<any>,
    ) { }

    public sendTokens = async (from_address: string, to_address: string, amount: string, denom: string): Promise<DeliverTxResponse> => {
        console.log("Sending tokens to", to_address, "amount", amount, "denom", denom);
        const walletPrefix = getPrefixFromWallet(from_address);
        if (walletPrefix !== getPrefixFromWallet(to_address)) {
            throw new Error('Wallets are not from the same chain');
        }

        const uToken = await getUToken(walletPrefix, new BigNumber(amount), denom);

        const message: MsgSendEncodeObject = {
            typeUrl: '/cosmos.bank.v1beta1.MsgSend',
            value: MsgSend.fromPartial({
                fromAddress: from_address,
                toAddress: to_address,
                amount: [
                    {denom: uToken.denom, amount: uToken.amount.toString()}
                ],
            })
        }
        const fees = (await getChain(walletPrefix)).fees.fee_tokens?.find((fee) => fee.denom === uToken.denom);
        if (!fees) {
            throw new Error('Invalid fee');
        }
        const fee = {
            amount: [
                {
                    denom: fees.denom,
                    amount: fees.fixed_min_gas_price.toString()
                }
            ],
            gas: '200000'
        }

        console.log('Message', message, 'Fee', fee)

        return await this.signAndBroadcast([message], fee);
    }
}