import Function from '../Function'
import {isDeliverTxSuccess} from '@cosmjs/stargate';
import RunFunction from "../../../interfaces/RunFunction";
import CosmosClient from "../../Cosmos/CosmosClient";

type SendCryptoTokensArgs = {
    to_wallet_address: string,
    amount: string,
    denom: string
}

export default class SendCryptoTokens implements Function {
    constructor(
        private readonly address: string,
        private readonly cosmosClient: CosmosClient,
    ) {}

    async run(functionArgs: SendCryptoTokensArgs): Promise<string> {
        const result = await this.cosmosClient.sendTokens(
            this.address,
            functionArgs.to_wallet_address,
            functionArgs.amount,
            functionArgs.denom
        )

        return (isDeliverTxSuccess(result) ? 'Tokens sent!' : 'Problem sending your tx!') + ` TX hash ${result.transactionHash}`;
    }

    supports(runFunction: RunFunction): boolean {
        return runFunction.functionName === this.getName()
    }

    getName(): string {
        return 'send_crypto_tokens'
    }
}