import { Box } from "@mui/material";

type Props = {
  children: React.ReactNode;
  fromUser?: boolean
};

export const GPTMessage = ({ children, fromUser }: Props) => {
  return (
    <Box
      flexDirection={fromUser ? "row-reverse" : "row"}
      gap={3}
      display="flex"
      alignItems={"flex-start"}
      style={{ marginTop: "32px" }}
    >
      <img
        alt="ChatGPT Avatar"
        src={`${process.env.PUBLIC_URL}/images/cosmosgpt.avatar.svg`}
      />
      <Box>{children}</Box>
    </Box>
  );
};
