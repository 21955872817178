import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ChainProvider } from "@cosmos-kit/react";
import { chains, assets } from "chain-registry";
import { wallets as keplrWallets } from "@cosmos-kit/keplr";
import { wallets as cosmostationWallets } from "@cosmos-kit/cosmostation";
import { wallets as leapWallets } from "@cosmos-kit/leap";
import "@interchain-ui/react/styles";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ChakraProvider } from "@chakra-ui/react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider
      theme={createTheme({
        typography: {
          allVariants: {
            color: "#000",
          },
        },
        palette: {
          primary: {
            main: "#556cd6",
          },
          secondary: {
            main: "#19857b",
          },
          error: {
            main: red.A400,
          },
        },
      })}
    >
      <ChakraProvider
        theme={{
          fonts: {
            body: "Inter, system-ui, sans-serif",
            heading: "Work Sans, system-ui, sans-serif",
          },
          colors: {
            primary: {
              "50": "#e5e7f9",
              "100": "#bec4ef",
              "200": "#929ce4",
              "300": "#6674d9",
              "400": "#4657d1",
              "500": "#2539c9",
              "600": "#2133c3",
              "700": "#1b2cbc",
              "800": "#1624b5",
              "900": "#0d17a9",
            },
          },
          breakPoints: {
            sm: "30em",
            md: "48em",
            lg: "62em",
            xl: "80em",
            "2xl": "96em",
          },
          shadows: {
            largeSoft: "rgba(60, 64, 67, 0.15) 0px 2px 10px 6px;",
          },
        }}
      >
        <ChainProvider
          chains={chains} // supported chains
          assetLists={assets} // supported asset lists
          wallets={[...keplrWallets, ...cosmostationWallets, ...leapWallets]} // supported wallets
          // walletConnectOptions={} // required if `wallets` contains mobile wallets
        >
          <CssBaseline />
          <App />
        </ChainProvider>
      </ChakraProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
