import {Typography} from "@mui/material";
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const BlinkAnimation = keyframes`
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
`;

export const BlinkingTypography = styled(Typography)`
  animation: ${BlinkAnimation} 3s linear infinite;
`;