import Function from '../Function'
import { StargateClient } from '@cosmjs/stargate';
import RunFunction from "../../../interfaces/RunFunction";

type GetWalletBalanceArgs = {
    wallet_address: string,
    denom?: string
}

export default class GetWalletBalance implements Function {
    constructor(
        private readonly stargateClient: StargateClient
    ) {
    }

    async run(functionArgs: GetWalletBalanceArgs): Promise<string> {
        const allBalances = await this.stargateClient.getAllBalances(functionArgs.wallet_address)

        let returnString = ''
        allBalances.forEach((balance) => {
            returnString += balance.denom + ': ' + balance.amount + '\n'
        })

        return returnString
    }

    supports(runFunction: RunFunction): boolean {
        return runFunction.functionName === this.getName()
            && 'wallet_address' in runFunction.functionArguments
            && runFunction.functionArguments.wallet_address !== undefined
    }

    getName(): string {
        return 'get_wallet_balance'
    }
}