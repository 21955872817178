import Function from './Function';
import Run from "../../interfaces/Run";
import {RunStatus} from "../../interfaces/RunStatus";
import RunFunction from "../../interfaces/RunFunction";
import GetWalletBalance from "./Functions/GetWalletBalance";
import {ChainContext} from "@cosmos-kit/core";
import SendCryptoTokens from "./Functions/SendCryptoTokens";
import {FunctionError} from "./FunctionError";
import CosmosClient from '../Cosmos/CosmosClient';

export default class FunctionManager {
    constructor (
        private readonly functions: Function[]
    ) {}

    static async create(chainContext: ChainContext): Promise<FunctionManager> {
        return new FunctionManager([
            new GetWalletBalance(await chainContext.walletRepo.getStargateClient()),
            new SendCryptoTokens(
                chainContext.address ?? '',
                new CosmosClient(chainContext.signAndBroadcast),
            )
        ]);
    }

    getFunctionNames(): string[] {
        return this.functions.map((func) => func.getName());
    }

    async runFunction(run: Run, runFunction: RunFunction): Promise<string> {
        if (run.status !== RunStatus.REQUIRES_ACTION) {
            throw new Error('Invalid run');
        }

        const func = this.getFunction(runFunction.functionName);
        if (!func) {
            throw new Error(`Invalid function ${runFunction.functionName}`);
        }
        console.log(`Found function ${func.constructor.name}`)
        if (!func.supports(runFunction)) {
            throw new Error('Invalid function arguments');
        }

        try {
            return await func.run(runFunction.functionArguments);
        } catch (error: any) {
            console.error(error)
            throw new FunctionError(error.message)
        }
    }

    getFunction(name: string): Function | undefined {
        return this.functions.find((func) => func.getName() === name);
    }
}
