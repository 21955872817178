import axios from "axios";
import Chat from "../interfaces/Chat";
import Run from "../interfaces/Run";
import {ChatMessage} from "../interfaces/ChatMessage";
import RunFunction from "../interfaces/RunFunction";

export default class CosmosGpt {
    constructor(
        public readonly url: string
    ) {
    }

    public async createChat(walletAddress: string): Promise<Chat> {
        const result = await axios.post(
            `${this.url}/api/v1/create-chat`,
            {walletAddress: walletAddress}
        )

        return {id: result.data.chatId}
    }

    public async queryChat(chat: Chat, query: string): Promise<Run> {
        const result = await axios.get(`${this.url}/api/v1/chat/${chat.id}/query?query=${query}`)

        return {id: result.data.runId}
    }

    public async pollRunId(chat: Chat, run: Run): Promise<Run> {
        const result = await axios.get(`${this.url}/api/v1/chat/${chat.id}/run/${run.id}`, {
            timeout: 5000
        })

        return result.data
    }

    public async getChatMessages(chat: Chat): Promise<ChatMessage[]> {
        const result = await axios.get(`${this.url}/api/v1/chat/${chat.id}/messages`)

        return result.data.messages
    }

    async respondToRun(chat: Chat, run: Run, runFunction: RunFunction, response: string) {
        await axios.post(`${this.url}/api/v1/chat/${chat.id}/run/${run.id}`, {
            outputs: [
                {
                    toolCallId: runFunction.toolCallId,
                    output: response
                }
            ]
        })
    }
}
