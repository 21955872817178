class StorageManager {
    public save(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    public get(key: string): any {
        return localStorage.getItem(key);
    }
}

export const storageManager = new StorageManager();
