import {
    FormControl,
    TextField,
    Tooltip,
    IconButton,
} from "@mui/material";
import {Icon} from "@chakra-ui/react";
import {FiNavigation, FiXCircle, FiTrash} from "react-icons/fi";
import {ConnectWalletButton} from "../cosmos-kit/react";
import {useChain} from "@cosmos-kit/react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {useState} from "react";

type Props = {
    onSend: (message: string) => void;
    onReset: () => void;
};

export const ChatInputText = ({onSend, onReset}: Props) => {
    const {connect, disconnect, isWalletConnecting, isWalletConnected} =
        useChain("osmosis");

    const [queryText, setQueryText] = useState("");

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log("send submit");
        setQueryText("");
        onSend(queryText);
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
                <Grid2 container spacing={2}>
                    <Grid2 xs={9}>
                        <TextField
                            style={{
                                backgroundColor: isWalletConnected ? "white" : "#F3F4F8",
                                color: "#7A808E",
                                border: "1px solid #D1D3DC",
                                borderRadius: "8px",
                                fontSize: "16px",
                                fontStyle: !isWalletConnected ? "italic" : "normal",
                            }}
                            value={queryText}
                            onChange={(e) => setQueryText(e.target.value)}
                            fullWidth
                            placeholder={
                                !isWalletConnected
                                    ? "Connect wallet to continue"
                                    : "Chat with CGPT here!"
                            }
                            disabled={!isWalletConnected}
                        />
                    </Grid2>
                    <Grid2
                        xs={3}
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                        }}
                    >
                        {isWalletConnected ? (
                            <Tooltip title="Send message">
                                <IconButton
                                    type={"submit"}
                                    style={{
                                        backgroundColor: "#7A808E",
                                        color: "black",
                                        borderRadius: "8px",
                                        minHeight: "100%",
                                        minWidth: "60px",
                                        fontSize: "25px",
                                    }}
                                >
                                    <Icon as={FiNavigation} mt={1}/>
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <ConnectWalletButton
                                onClickConnectBtn={connect}
                                isLoading={isWalletConnecting}
                            />
                        )}

                        {isWalletConnected && (
                            <Tooltip title="Disconnect">
                                <IconButton
                                    style={{
                                        backgroundColor: "#262A34",
                                        color: "white",
                                        borderRadius: "8px",
                                        minHeight: "100%",
                                        minWidth: "60px",
                                        fontSize: "25px",
                                    }}
                                    onClick={() => disconnect()}
                                >
                                    <Icon as={FiXCircle} mt={1}/>
                                </IconButton>
                            </Tooltip>
                        )}
                        {isWalletConnected && (
                            <Tooltip title="Reset">
                                <IconButton
                                    style={{
                                        backgroundColor: "#262A34",
                                        color: "white",
                                        borderRadius: "8px",
                                        minHeight: "100%",
                                        minWidth: "60px",
                                        fontSize: "25px",
                                    }}
                                    onClick={() => onReset()}
                                >
                                    <Icon as={FiTrash} mt={1}/>
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid2>
                </Grid2>
            </FormControl>
        </form>
    );
};
