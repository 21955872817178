import {Box} from "@mui/material";
import React, {useEffect} from "react";
import {GPTMessage} from "./GPTMessage";
import {RunStatus} from "../../interfaces/RunStatus";
import {BlinkingTypography} from "../shared/BlinkingTypography";
import {ChatStatus} from "../../interfaces/ChatStatus";
import Markdown from "react-markdown";

type Props = {
  chatStatus: ChatStatus;
  runStatus?: RunStatus;
};

export const SystemMessage = ({ chatStatus, runStatus }: Props) => {
  const [statusMessage, setStatusMessage] = React.useState<string|undefined>(undefined);

  useEffect(() => {
    switch (chatStatus) {
      case ChatStatus.READY:
        setStatusMessage(undefined);
        break;
      case ChatStatus.RESTORING:
        setStatusMessage("Void recognises you... restoring connection...");
        break;
      case ChatStatus.QUERYING:
        setStatusMessage("Querying the void... 🌌");
        break;
      case ChatStatus.ERROR:
        setStatusMessage('There was an internal error. Please try again');
        setTimeout(() => {
          setStatusMessage(undefined)
        }, 5000)
        break;
      case ChatStatus.LOADING:
        switch (runStatus) {
          case RunStatus.COMPLETED:
            setStatusMessage("Sound detected from void... decoding it... 🛸");
            break;
          case RunStatus.EXPIRED:
            setStatusMessage("Void hasn't responded we gave up. Try again!");
            setTimeout(() => {
              setStatusMessage(undefined);
            }, 5000);
            break;
          case RunStatus.IN_PROGRESS:
            setStatusMessage("Listening to the void... 🌌");
            break;
          case RunStatus.REQUIRES_ACTION:
            setStatusMessage("Need more information to decode void's message...");
            break;
        }
    }
  }, [chatStatus, runStatus]);

  return (
      <>
        {statusMessage && <GPTMessage fromUser={false}>
          <Box textAlign="left">
            <BlinkingTypography><Markdown>{statusMessage}</Markdown></BlinkingTypography>
          </Box>
        </GPTMessage>}
      </>
  );
};
