import React, {useEffect} from "react";
import "./App.css";
import {useChain} from "@cosmos-kit/react";
import {Header} from "./components/Header/Header";
import {GPTMessage} from "./components/GPTMessage/GPTMessage";
import {Box, Container, Grid, Stack, Typography,} from "@mui/material";
import {ChatInputText} from "./components/ChatInputText/ChatInputText";
import {storageManager} from "./services/LocalStorage";
import CosmosGpt from "./services/CosmosGpt";
import {ChatMessage} from "./interfaces/ChatMessage";
import Chat from "./interfaces/Chat";
import {sleep} from "./util/sleep";
import Run from "./interfaces/Run";
import {SystemMessage} from "./components/GPTMessage/SystemMessage";
import {RunStatus} from "./interfaces/RunStatus";
import {AxiosError} from "axios";
import {ChatStatus} from "./interfaces/ChatStatus";
import FunctionManager from "./services/FunctionService/FunctionManager";
import Markdown from "react-markdown";

const cosmosGpt = new CosmosGpt(process.env.REACT_APP_API_URL ?? '');

function App() {
    const chainContext = useChain('osmosis');
    const [runStatus, setRunStatus] = React.useState<RunStatus | undefined>(undefined);
    const [chatStatus, setChatStatus] = React.useState<ChatStatus>(ChatStatus.READY);
    const [messages, setMessages] = React.useState<ChatMessage[]>([]);
    const [chat, setChat] = React.useState<Chat | undefined>(undefined);
    const firstRender = React.useRef(true);
    const messagesEndRef = React.useRef<HTMLDivElement | null>(null);

    const handleSend = async (query: string) => {
        if (chat === undefined) {
            throw new Error('chat is undefined')
        }

        setMessages([...messages, {message: query, isSystem: false, role: 'user'}])
        setChatStatus(ChatStatus.QUERYING)

        let run: Run|undefined = undefined;
        try {
            run = await cosmosGpt.queryChat(chat, query)
        } catch (e: AxiosError|any) {
            console.error(e)
            setChatStatus(ChatStatus.ERROR)
            if (e.response.status === 400) {
                const regex = /Can't add messages to .+ while a run (.+?) is active/
                const matches = e.response.data.error.match(regex)
                if (matches) {
                    console.info('Trying to recover from a run in progress')
                    run = {id: matches[1], status: RunStatus.IN_PROGRESS}
                    setChatStatus(ChatStatus.LOADING)
                }
            }
        }

        if (run === undefined) {
            return
        }

        let runStatus = run.status
        setRunStatus(runStatus)
        let handlingRun = false
        do {
            try {
                const updatedRun = await cosmosGpt.pollRunId(chat, run)
                runStatus = updatedRun.status
                setRunStatus(runStatus)
                if (runStatus === RunStatus.REQUIRES_ACTION && !handlingRun) {
                    console.log('Handling functions');
                    const functionManager = await FunctionManager.create(chainContext)
                    for (const runFunction of updatedRun.functions ?? []) {
                        try {
                            const response = await functionManager.runFunction(updatedRun, runFunction)
                            await cosmosGpt.respondToRun(chat, run, runFunction, response)
                        } catch (error: any) {
                            console.error(error.message)
                            await cosmosGpt.respondToRun(chat, run, runFunction, error.message)
                        }
                        handlingRun = true
                    }
                }
            } catch (error: any) {
                if (error instanceof AxiosError && error.code === 'ECONNABORTED') {
                    console.info('Request timed out')
                } else {
                    console.error(error, runStatus)
                    setChatStatus(ChatStatus.ERROR)
                    return
                }
            }

            await sleep(1000)
        } while (runStatus === RunStatus.IN_PROGRESS || runStatus === RunStatus.REQUIRES_ACTION)
        const chatMessages = await cosmosGpt.getChatMessages(chat)
        setMessages(chatMessages.reverse())
        setChatStatus(ChatStatus.READY)
    }

    const handleReset = async () => {
        setChatStatus(ChatStatus.CREATING)
        setMessages([]);
        if (chainContext.address) {
            cosmosGpt.createChat(chainContext.address).then((chat) => {
                setChat(chat)
                localStorage.setItem('chatId', chat.id)

                cosmosGpt.getChatMessages(chat).then((messages) => {
                    setMessages(messages)
                    setChatStatus(ChatStatus.READY)
                });
            })
        }
    }

    useEffect(() => {
        if (chainContext.address && firstRender.current) {
            firstRender.current = false

            console.log('walletConnected', chainContext.address)

            storageManager.save('walletAddress', chainContext.address)

            if (localStorage.getItem('chatId')) {
                setChatStatus(ChatStatus.RESTORING)
                setChat({id: localStorage.getItem('chatId')} as Chat)
                cosmosGpt.getChatMessages({id: localStorage.getItem('chatId')} as Chat).then((messages) => {
                    setMessages(messages.reverse())
                    setChatStatus(ChatStatus.READY)
                });
            } else {
                setChatStatus(ChatStatus.CREATING)
                cosmosGpt.createChat(chainContext.address).then((chat) => {
                    setChat(chat)
                    localStorage.setItem('chatId', chat.id)

                    cosmosGpt.getChatMessages(chat).then((messages) => {
                        setMessages(messages)
                        setChatStatus(ChatStatus.READY)
                    });
                })
            }
        }
    }, [chainContext, firstRender]);

    useEffect(() => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 10);
    }, [messages, chatStatus]);

    return (
        <Container
            maxWidth="lg"
            className="App"
            sx={{minHeight: "100vh", display: "flex", flexDirection: "column"}}
        >
            <Header/>
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{overflowY: "auto", flex: 1, width: "100%"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12} sm={10}>
                            <Grid xs={12}>
                                <Stack spacing={{sm: 2}} direction="column" flex={1}>
                                    <GPTMessage>
                                        <Box textAlign="left">
                                            <Typography>Hey! 👋</Typography>
                                            <Typography fontWeight="bold">This is a MVP version with minimal capabilities.</Typography>
                                            <Typography gutterBottom>
                                                I help you to save a lot of time managing your tokens
                                                while you keep full control over your wallet.
                                            </Typography>
                                            <Typography>
                                                Most of it's features are disabled, the mvp version currently can:
                                                <ul style={{paddingLeft: "1.5rem"}}>
                                                    <li>Answer general questions about crypto</li>
                                                    <li>Can fetch your balance of your osmosis wallet (e.g.: What is my balance?)</li>
                                                    <li>Can send osmosis using natural language (e.g.: Send 10 osmo to osmoy)</li>
                                                </ul>
                                            </Typography>
                                        </Box>
                                    </GPTMessage>
                                    {messages.map((message, i) => (
                                        <GPTMessage fromUser={!message.isSystem} key={i}>
                                            <Box textAlign="left">
                                                <Typography>
                                                    <Markdown>{message.message}</Markdown>
                                                </Typography>
                                            </Box>
                                        </GPTMessage>
                                    ))}
                                    <SystemMessage chatStatus={chatStatus} runStatus={runStatus} />
                                    <div ref={messagesEndRef}/>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        position: "sticky",
                        bottom: 0,
                        width: "100%",
                        backgroundColor: "white",
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            paddingY: 3,
                        }}
                    >
                        <Grid item xs={12} sm={10}>
                            <ChatInputText
                                onSend={(query) => handleSend(query)}
                                onReset={handleReset}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}

export default App;
